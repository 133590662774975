@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.css-to7de5:empty {
  display: none !important;
}

code {
  font-family: 'Inter', sans-serif;
}

.sidebargradient{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.hoversidebar:hover{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}



input:checked ~ .dot {
  @apply translate-x-full bg-[#48bb78];
}

.input-body {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2;
}
.input-error{
  @apply min-h-[1rem] h-4 max-h-9  text-[9px] ml-1;
}




.mainbody_height {
  height: calc(100vh - 60px);
}

.mainleftbar_height {
  height: calc(90vh - 150px);
  overflow-y: hidden;
}


@keyframes blink {
  50% {
    opacity: 20%;
  }
}

.animate-blink {
  animation: blink 2s linear infinite;
}

:root {
  --primary: #9ca3af;
  --secondary: #e5e7eb;
}


* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--secondary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  @apply w-[6px] rounded-lg;

}
*::-webkit-scrollbar-track {
  background: var(--primary);
}
*::-webkit-scrollbar-thumb {
  @apply bg-[color:var(--secondary)] border-[color:var(--primary)] rounded-lg border-2 border-solid;
}

dialog::backdrop {
  background-color: #000000B2;
}

dialog {
  @apply z-10;
}

/* .css-to7de5:only-of-type::before{
  border-bottom: 1px solid red;
  content:attr(datatitle) ;
}
.css-to7de5:only-of-type::before{
  content:'level 0' ;
} */

/* .css-to7de5   :first-of-type::before{
  content:'level 0' ;
  position: absolute;
  right: 0px;
  font-size: 12px;
  white-space: nowrap;
  padding-left:4px ;
} */

[datatitle1]::before{
  content: attr(datatitle1);
  position: absolute;
  top:-1rem;
  white-space: nowrap;
  font-size:10px;
}

[datatitle]::before{
  content: attr(datatitle);
  position: absolute;
  top:-1rem;
  right: -.25rem;
  white-space: nowrap;
  font-size:10px;
}
 /* custom select tag css */
 .selectcontainer {
    outline: none;
  }
  .value {
    @apply grow flex gap-[0.5em] flex-wrap;
  }
  .clear-btn {
    @apply text-[#777] cursor-pointer text-[1.25em] p-0 border-[none];
    background: none;
    outline: none;
  }
  .clear-btn:focus,
  .clear-btn:hover {
    @apply text-[#333];
  }
  .divider {
    @apply bg-[#777] self-stretch w-[0.05em];
  }
  .caret {
    @apply border-t-[#777] border-[0.25em] border-solid border-transparent;
    translate: 0 25%;
  }
  .options {
    @apply absolute hidden max-h-[10rem] overflow-y-auto w-full bg-[white] z-[100] m-0 p-0 rounded-[0.25em] border-[0.05em] border-solid border-[#777] left-0 top-[calc(100%_+_0.25em)];
    list-style: none;
  }
  .options.show {
    @apply block;
  }
  .option {
    @apply cursor-pointer px-[0.5em] py-[0.25em];
  }
  .option.selected {
    @apply bg-[hsl(200,100%,70%)];
  }
  .option.highlighted {
    @apply bg-[hsl(200,100%,50%)] text-[white];
  }
  
  .option-placeholder {
    @apply flex items-center gap-1 cursor-pointer px-1 py-0.5 rounded-[0.25em] ;
    background: none;
  }
  
  
  .option-badge {
    @apply flex items-center gap-[0.25em] cursor-pointer px-1 py-0.5 rounded-[0.25em] outline outline-[0.25px] outline-[#777];
    background: none;
  }
  .option-badge:hover,
  .option-badge:focus {
    @apply bg-[hsl(0,100%,90%)] border-[hsl(0,100%,50%)];
  }
  .option-badge:hover > .remove-btn,
  .option-badge:focus > .remove-btn {
    @apply text-[hsl(0,100%,50%)];
  }
  .option-badge > .remove-btn {
    @apply text-[1.25em] text-[#777];
  }
   /* custom select tag css */
  